import React from 'react'
import css from './UserPanel.module.css'
import { useState } from 'react';

export default function UserPanel() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const logout = () => { localStorage.clear(); setUser(null)};
  return (
    <div className={css.frame}>
        <div className={css.avatar}>

        </div>
        <div className={css.info}>
            {user? user.name : "login"}
            {user?<button onClick={logout}>logout</button> : null}
        </div>
    </div>
  )
}
