import React, { Fragment, useState, useEffect } from 'react'
import css from './News.module.css'
import axios from '../../Axios';
import { Link } from 'react-router-dom';
const News = (props) =>  {
    const [news, setNews] = useState(null);
    //console.log(news);
    let newsList = !news ? "Loading" : news.map(x=> {
        
        return (<article key={x.id}>
            <h2>{x.title}</h2>
            <p dangerouslySetInnerHTML={{__html:x.content}}></p>
        </article>)
    })

    const loadNewsHandler = () => {
         axios.get('News/')
         .then((rv) => {
            setNews(rv.data);
         }).catch((err)=> console.log(err))
    }

    useEffect(() => {
        loadNewsHandler();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    ,[])


    //console.log(news);
    return  (
        <Fragment>
    <section className={css.tiles}>
        <div className={css.tile}>
        <svg width="68" height="76" viewBox="0 0 68 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.75 75.5H60.25C64.3863 75.5 67.75 72.1363 67.75 68V15.5C67.75 11.3637 64.3863 8 60.25 8H52.75V0.5H45.25V8H22.75V0.5H15.25V8H7.75C3.61375 8 0.25 11.3637 0.25 15.5V68C0.25 72.1363 3.61375 75.5 7.75 75.5ZM60.25 23L60.2537 68H7.75V23H60.25Z" fill="white"/>
            <g transform="translate(15,33)">
                <path d="M0.25 0.25H7.75V7.75H0.25V0.25ZM0.25 15.25H7.75V22.75H0.25V15.25ZM15.25 0.25H22.75V7.75H15.25V0.25ZM15.25 15.25H22.75V22.75H15.25V15.25ZM30.25 0.25H37.75V7.75H30.25V0.25ZM30.25 15.25H37.75V22.75H30.25V15.25Z" fill="white"/>
            </g>
        </svg>
        <h2>Kalendář</h2>
        <p>24.3.2024 Valná hromada</p>
        <p></p>
        </div>

        <div className={css.tile}>
        <svg width="54" height="78" viewBox="0 0 54 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.75 39H53.25M0.75 39L0.75 3.75C0.75 3.45453 0.808198 3.16194 0.921272 2.88896C1.03434 2.61598 1.20008 2.36794 1.40901 2.15901C1.61794 1.95008 1.86598 1.78434 2.13896 1.67127C2.41194 1.55819 2.70453 1.5 3 1.5H51C51.2955 1.5 51.5881 1.55819 51.861 1.67127C52.134 1.78434 52.3821 1.95008 52.591 2.15901C52.7999 2.36794 52.9657 2.61598 53.0787 2.88896C53.1918 3.16194 53.25 3.45453 53.25 3.75L53.25 39H0.75ZM0.75 39L0.75 74.25C0.75 74.8467 0.987053 75.419 1.40901 75.841C1.83097 76.2629 2.40326 76.5 3 76.5H51C51.5967 76.5 52.169 76.2629 52.591 75.841C53.0129 75.419 53.25 74.8467 53.25 74.25V39H0.75Z" stroke="white" strokeWidth="1.5"/>
            <g transform="translate(6,0)">
                <path d="M38.75 1.5C38.75 6.47281 36.7746 11.2419 33.2583 14.7583C29.7419 18.2746 24.9728 20.25 20 20.25C15.0272 20.25 10.2581 18.2746 6.74175 14.7583C3.22544 11.2419 1.25 6.47281 1.25 1.5M31.25 39C31.25 41.9837 30.0647 44.8452 27.955 46.955C25.8452 49.0647 22.9837 50.25 20 50.25C17.0163 50.25 14.1548 49.0647 12.045 46.955C9.93526 44.8452 8.75 41.9837 8.75 39C8.75 36.0163 9.93526 33.1548 12.045 31.045C14.1548 28.9353 17.0163 27.75 20 27.75C22.9837 27.75 25.8452 28.9353 27.955 31.045C30.0647 33.1548 31.25 36.0163 31.25 39ZM38.75 76.5C38.75 71.5272 36.7746 66.7581 33.2583 63.2417C29.7419 59.7254 24.9728 57.75 20 57.75C15.0272 57.75 10.2581 59.7254 6.74175 63.2417C3.22544 66.7581 1.25 71.5272 1.25 76.5H38.75Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>

        </svg>
        <h2>Hřiště</h2>
        <p>Pro zapůjčení hřiště a tělocvičny kontaktujte správce.</p>
        </div>

        <div className={css.tile}>
        <svg width="79" height="85" viewBox="0 0 79 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M77.5144 18.6933L60.0567 1.23558C59.6659 0.842615 59.2011 0.531069 58.6891 0.318982C58.1771 0.106896 57.6281 -0.00151872 57.0739 1.60703e-05H4.21875C3.10027 0.00131869 2.02797 0.446211 1.23708 1.2371C0.446195 2.02799 0.00130262 3.10028 0 4.21877V80.1563C0.00130262 81.2747 0.446195 82.347 1.23708 83.1379C2.02797 83.9288 3.10027 84.3737 4.21875 84.375H74.5312C75.6497 84.3737 76.722 83.9288 77.5129 83.1379C78.3038 82.347 78.7487 81.2747 78.75 80.1563V21.6761C78.7515 21.1219 78.6431 20.5729 78.431 20.0609C78.2189 19.5489 77.9074 19.0841 77.5144 18.6933ZM50.625 5.62502V22.5H25.3125V5.62502H50.625ZM73.125 78.75H5.625V5.62502H19.6875V28.125H56.25V5.62502H56.4913L73.125 22.2587V78.75Z" fill="white"/>
            <g transform="translate(22,35)"> 
                <path d="M16.6719 0.5625C13.4734 0.5625 10.3467 1.51096 7.68727 3.28795C5.02782 5.06494 2.95503 7.59064 1.73102 10.5457C0.507006 13.5007 0.18675 16.7523 0.810746 19.8893C1.43474 23.0264 2.97496 25.9079 5.23664 28.1696C7.49832 30.4313 10.3799 31.9715 13.5169 32.5955C16.6539 33.2195 19.9056 32.8992 22.8606 31.6752C25.8156 30.4512 28.3413 28.3784 30.1183 25.719C31.8953 23.0595 32.8438 19.9329 32.8438 16.7344C32.8391 12.4468 31.1338 8.33608 28.102 5.30427C25.0702 2.27247 20.9595 0.567153 16.6719 0.5625ZM16.6719 27.2812C14.5859 27.2812 12.5468 26.6627 10.8124 25.5038C9.07793 24.3449 7.72611 22.6977 6.92784 20.7705C6.12957 18.8433 5.92071 16.7227 6.32766 14.6768C6.73461 12.6309 7.73911 10.7516 9.21411 9.2766C10.6891 7.8016 12.5684 6.79711 14.6143 6.39016C16.6602 5.9832 18.7808 6.19206 20.708 6.99033C22.6352 7.7886 24.2824 9.14042 25.4413 10.8748C26.6002 12.6093 27.2188 14.6484 27.2188 16.7344C27.2156 19.5306 26.1034 22.2114 24.1262 24.1886C22.1489 26.1659 19.4681 27.2781 16.6719 27.2812Z" fill="white"/>
            </g>
        </svg>
        <h2>Dokumenty</h2>
        <p>Zápisy z akcí a jiné dokumenty naleznete v sekci <Link to='/Dokumenty'>Dokumenty</Link></p>
       
        </div>
    </section>

    <section className={css.articles}>
        {newsList}
    </section>

    </Fragment>
    );
}

export default News;