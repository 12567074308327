import React from 'react'

import axios from '../../Axios';
const Contacts = (props) =>  {
    return <div className='mainContent columns'>
        <div className='indentContent'>
            <h2>Tělocvičná jednota Sokol Hnojice</h2>
            <div className='indentContent'>
                <p>IČ 60803207</p>
                <p>Hnojice 179, 785 01 Hnojice</p>
           

                <h3>Starosta</h3>  
                <div className='indentContent'>
                    <h4>Zdeněk Jurásek</h4>
                        <div className='indentContent'>
                            <a className='email' href='mailto:jurasek.zdenek@centrum.cz'>jurasek.zdenek@centrum.cz</a>
                            <p className='phone'>+420 737 340 226</p>
                        </div>
                </div>

                <h3>Místostarosta</h3>  
                <div className='indentContent'>
                    <h4>Kolář Antonín</h4>
                    <div className='indentContent'>
                        <a className='email' href='mailto:kolar@stolarstvi.cz'>kolar@stolarstvi.cz</a>
                        <p className='phone'>+420 723 121 996</p>
                    </div>
                </div>

                <h3>Jednatel</h3>
                <div className='indentContent'>
                    <h4>Jašíček Luděk</h4>
                        <div className='indentContent'>
                            <a className='email' href='mailto:ludek.jasicek@volny.cz'>ludek.jasicek@volny.cz</a>
                            <p className='phone'>+420 723 662 764</p>
                        </div>
                </div>

                <h3>Hospodář</h3>
                <div className='indentContent'>
                    <h4>Dan Šperlich</h4>
                        <div className='indentContent'>
                            <a className='email' href='mailto:sperlichdan@seznam.cz'>sperlichdan@seznam.cz</a>
                            <p className='phone'>+420 733 304 033</p>
                        </div>
                </div>

                <h3>Správce tělocvičny</h3>
                <div className='indentContent'>
                    <h4>Ondřej Suchomel</h4>
                        <div className='indentContent'>
                            <p className='phone'>+420 774 384 884</p>
                        </div>
                </div>
            </div>
            </div>
        <div className='centerContent'>
        
            <iframe title="Hnojice mapa" style={{"border":"none", "marginTop":"2.5em"}} src="https://frame.mapy.cz/s/ducafabepe" width="500" height="333" frameBorder="0"></iframe>
        </div>
    </div>;
}

export default Contacts;