import React from 'react'
import css from './GalleryTile.module.css'
const GalleryTile = (props) =>  {
    return  <div className={css.tile} onClick={props.onclick}>
    <img src={props.img} alt="Placeholder" className={css.tileimage} />
    <div className={css.tileTitle}>{props.title}</div>
    <div className={css.tileSubtitle}>{props.subTitle}</div>
    <div className={css.tilebuttons}>
      {props.editHandler ? <button className={css.tilebutton + " " + css.edit}>Upravit</button> : null}
      {props.deleteHandler ? <button className={css.tilebutton + " " + css.delete}>Smazat</button> : null}
    </div>
  </div>;
}

export default GalleryTile;