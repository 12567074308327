import React,{useState , useEffect} from 'react'
import GalleryTile from '../../Components/Display/Tile/GalleryTile';
import axios from '../../Axios';
const Gallery = (props) =>  {
   const [galleries, setGalleries] = useState(null);
   const [activeGallery, setActiveGallery] = useState(null);
   const [fileList, setFileList] = useState(null);
   //console.log(news);
   let galleryList = !galleries ? "Loading" : galleries.map(x=> {
       
       return <GalleryTile key={x.id} title={x.name} description={x.description} subTitle={x.description} img={x.coverImg} onclick={()=>galleryClickHandler(x.id)} />
          
   })

   const loadGalleryHandler = () => {
        axios.get('Gallery')
        .then((rv) => {
         setGalleries(rv.data);
         setFileList(null);
        }).catch((err)=> console.log(err))
   }

   const loadFileListHandler = (i)=> {
      axios.get('Gallery/'+i)
      .then((rv) => {
         setFileList(rv.data);
      }).catch((err)=> console.log(err))
   }

   const galleryClickHandler = (i) => {
      loadFileListHandler(i);
      setActiveGallery(galleries[i]);
   }

   const galleryCloseHandler = () => {
      setFileList(null);
      setActiveGallery(null);
   }

   useEffect(() => {
       loadGalleryHandler();
       }// eslint-disable-next-line react-hooks/exhaustive-deps
   ,[])

   let files = null;
       if(fileList) {
         files = fileList.map(x=> {
            return <img src={x.thumbnail} loading="lazy" alt={x.name} key={x.id} />;
         });
       }
     
    return <div className='mainContent columns'>
      {activeGallery && files ? <h1 style={{width:'100%', cursor:'pointer'}} onClick={galleryCloseHandler}>↩ {activeGallery.name}</h1> : null}
            {files ? files :galleryList}
    </div>;
}

export default Gallery;