import React from 'react'

import axios from '../../Axios';
const History = (props) =>  {
    return <div className='mainContent historie'>
        <h1>Historie</h1>
        <p>V obci se již před první světovou válkou, v roce 1913, jevil zájem o tělesnou výchovu. Byli to Josef a Vojtěch Holoubkovi, Jan Sobota a Čeněk Ošťádal, kteří měli v sadě u Holoubků postavenou hrazdu, na které cvičili a později navštěvovali cvičení sokolské jednoty žerotínské, která byla založena již v roce 1927.</p>

        <p>Cvičení sokolů v Hnojicích dne 3. července 1949V dubnu roku 1919 si Vojtěch Loutocký postavil na zahradě provizorní bradla a ze školy vypůjčenou hrazdu. Stává se mluvčím mladých nadšenců, kteří myšlenku Sokola v Hnojicích nehodlají vypustit z hlavy. 24. července 1919 se koná veřejná schůze, na které mluví župní vzdělavatel Dr. Evžen Dosoudil z Olomouce o cílech a úkolech Sokola. Ustavuje se přípravný výbor ve složení: Michal Sedláček, Jan Vyhnánek, Bohumil Krček, Vojtěch Ledvina a Vojtěch Loutocký. Za zakládající členy se také s příspěvkem 200,- Kč přihlásili Josef Bajer s chotí, Klement Introvič s chotí, Michal Sedláček a Bohumil Krček.</p>

        <p>Protože byly vykonány potřebné přípravné práce, bylo povoleno svolání první ustavující valné hromady na den 10. září 1919. Odbor Sokola v Hnojicích, župou přiřazený k mateřské jednotě žerotínské, vchází do života ustavující valnou hromadou dne 10. září 1919. Schůzi předsedal a řídil ji starosta obce Josef Bajer.</p>

        <p>Počátkem roku 1920 se rozdělují úkoly k zajištění dovozu stavebního materiálu na úpravu kolny na tělocvičnu. Přestavba na tělocvičnu byla v květnu dokončena a vyžádala si náklad 15.000 ,- Kč. 8. prosince 1920 byl na župní schůzi prohlášen hnojický Sokol samostatnou jednotkou.</p>

        <p>V červenci roku 1921 byla podána žádost Státnímu pozemkovému úřadu v Praze o přidělení pozemku o rozloze asi 5.000 m² s polní kůlnou z vyvlastněného velkostatku, náležícího k panství knížete Jana Lichtensteina. pozemek měl být stavebním místem pro příští hnojickou sokolovnu a mělo na něm být zřízeno také velké hřiště. Žádost byla kladně vyřízena, ale pro nedostatek peněz byla koupě odložena na pozdější dobu. Při parcelaci pozemků lichtensteinského velkostatku v dubnu 1925 byl zakoupen 1 ha pole a polní kůlna, se kterou se počítalo, že se upraví na sokolovnu. K tomu ale nedošlo, protože 27. srpna 1929 kůlna vyhořela.</p>

        <p>Osud byl ale neúprosný. Po mnichovské dohodě 29. září 1938 vyhlásili Němci 15. března 1939 Protektorát. Začala doba německé hrůzovlády a pronásledování všech Čechů. Spolkové jmění bylo zajištěno a sokolský život ochromen. 12. dubna 1941 zastavili nacisté činnost Sokola. 11. října 1941 byl Sokol rozpuštěn. Nastala těžká doba pronásledování. Umučeni byli josef Urban, jeden z prvních cvičitelů jednoty, a Vojtěch Válek, popraven byl dřívější žák jednoty Jindřich Nevěřil a na barikádách padl Miroslav Myšák. Činnost jednoty byla opět zahájena 12. června 1945 pravidelným cvičením všech složek.</p>

        <p>sokolV roce 1949 a 1950 činnost jednoty značně ochabla a stalo se, že poprvé od založení jednoty nebylo uspořádáno veřejné cvičení. Byla také zrušena pobočka v Babicích, protože nevyvíjela žádnou činnost.</p>

        <p>V roce 1958 udělil Ústřední výbor Československého svazu tělesné výchovy Františku Malaskovi veřejné uznání za zásluhy o rozvoj Československé tělesné výchovy II. stupně.</p>

        <p>Velkou brzdou pro zdárné rozvíjení tělovýchovné činnosti byla naprosto nevyhovující tělocvična, a to jak po stránce prostorové, tak i zdravotní. Neměla sociální zařízení ani šatny. MNV podal na ONV v Olomouci požadavek na výstavbu nové tělocvičny, který byl schválen. Projekt počítal s výstavbou vlastního sálu o ploše 270 m² a nízkých přístavků, ve kterých jsou umístěny dvě šatny, umývárna, klubovna, místnost pro cvičitele, zdravotní místnost, WC pro muže a ženy, nářaďovna, kotelna s uhelnou a průběžná chodba, která má vstupy na obou koncích. Stavba byla započata 6. června 1967 a uvedena do provozu dne 27. září 1971. Bylo odpracováno 30.954 brigádnických hodin, náklady na výstavbu byly 840.000,- Kčs.</p> 

    </div>;
}

export default History;