import './App.css';
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Administration from './Pages/Admin/Administration';
import Home from './Pages/Home';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/admin/*' element={<Administration/>} />
          <Route path='/*' index element={<Home/>} />
        </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
