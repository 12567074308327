import React from 'react'

import axios from '../../Axios';
const Documents = (props) =>  {

    return <div className='mainContent'>
        <h3>Valná hromada</h3>  
        <div className='indentContent'>
            <a href='Files/ZÁPIS Z USNESENI__na_VH_12.03.2023.pdf' className='download pdf' target="_blank">ZÁPIS Z USNESENI__na_VH_12.03.2023.pdf</a>
            <a href='Files/ZAPIS_z_VH_12.03.2023.pdf' className='download pdf' target="_blank">ZAPIS_z_VH_12.03.2023.pdf</a>
            <a href='Files/ZÁPIS Z USNESENI__na_VH_13.3.2022.pdf' className='download pdf' target="_blank">ZÁPIS Z USNESENI__na_VH_13.3.2022.pdf</a>
            <a href='Files/ZAPIS_z_VH_13.3.22.pdf' className='download pdf' target="_blank">ZAPIS_z_VH_13.3.22.pdf</a>
        </div>

        <h3>Výběrové schůze</h3>  
        <div className='indentContent'>
            <a href='Files/Zapis_z_vyborove_schuze_z_25.1.2024.pdf' className='download pdf' target="_blank">Zapis_z_vyborove_schuze_z_25.1.2024.pdf</a>
        </div>
    </div>;
}

export default Documents;