import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import css from './Administration.module.css'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const Administration = () => {
const onEditorStateChange = (data) => {
console.log(draftToHtml(data));
}
        return( 
            <div style={{height:'100%'}}>
                <div className={css.Header}>
                    Header
                </div>
                <div className={css.Wrapper}>
                    <div className={css.Menu}>
                        <NavLink to='/'>
                            Nastavení
                        </NavLink>
                    </div>
                    <div className={css.Content}>
                        <Routes>
                            <Route path='/*'   element={
                            <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onContentStateChange={onEditorStateChange}
                                toolbar={{
                                        
                                    }}
                                />}/> 
                        </Routes>
                    </div>
                </div>
            </div>
       

        )
      
}
 
export default Administration;