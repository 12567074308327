import React, {useState,  Fragment} from 'react'
import { NavLink,  Route, Routes} from "react-router-dom";

import UserPanel from '../Components/Functional/UserPanel/UserPanel';
import Contacts from './Web/Contacts';
import Documents from './Web/Documents';
import Gallery from './Web/Gallery';
import News from './Web/News';
import History from './Web/History';
const Home = (props) =>  {
    const [openMenu, setOpenMenu] = useState(false);
    const hamburgerClickHandler = () => {
        setOpenMenu(!openMenu);
    }
    /* const [content, setContent] = useState('');
    const navigate = useNavigate();
    const  menuClickHandler = (to) => {
        loadContentHandler(to);
       }

       const loadContentHandler = (loc) => {
           if(!loc)
                loc = "Novinky";
           let menuItem = menu.find(x=>x.name === loc);
            axios.get('Content/all/' + (menuItem ? menuItem.id : loc))
            .then((rv) => {
               setContent(rv.data);
            }).catch((err)=> console.log(err))
       }

   useEffect(() => {
        axios.get('Menu/')
            .then((rv) => {
                setMenu(rv.data);
                if(rv.data && rv.data.length > 0){
                    navigate(rv.data[0].name);
                    loadContentHandler(rv.data[0].id);
                }
            }).catch((err)=> console.log(err))
            
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    ,[])
    dangerouslySetInnerHTML={{__html: (content ? content.map(i=>i.content).join('') : "")}}
    */

        return( 
            <Fragment>
                <div className='bgImage'></div>
                <div className='main'>
                    <header>
                        <div className='navContainer'>  
                            <div className='erb'>
                                <img src='public/Resources/erb.png' alt='Erb' title='Sokol Hnojice'/>
                                <span id='erbName'>Sokol Hnojice</span>
                                <div onClick={hamburgerClickHandler} className={['hamburger', (openMenu ? 'active' :'')].join(' ')}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <nav className={(openMenu ? 'fullscreen' :'')}>
                                <ul>
                                    <li><NavLink to='Novinky' onClick={(openMenu ? hamburgerClickHandler : null)}>Novinky</NavLink></li>
                                    <li><NavLink to='Historie' onClick={(openMenu ? hamburgerClickHandler : null)}>Historie</NavLink></li>
                                    <li><NavLink to='Galerie' onClick={(openMenu ? hamburgerClickHandler : null)}>Galerie</NavLink></li>
                                    <li><NavLink to='Dokumenty' onClick={(openMenu ? hamburgerClickHandler : null)}>Dokumenty</NavLink></li>
                                    <li><NavLink to='Kontaky' onClick={(openMenu ? hamburgerClickHandler : null)}>Kontaky</NavLink></li>
                                </ul>
                            </nav>
                            <div className='userPanel'>
                                <UserPanel  />
                            </div>
                        </div>
                    </header>
               
                <main>
                    <Routes>
                        <Route path='/Galerie' element={<Gallery/>}  />
                        <Route path='/Kontaky' element={<Contacts/>}  />
                        <Route path='/Historie' element={<History/>}  />
                        <Route path='/Dokumenty' element={<Documents/>}  />
                        <Route path='*' element={<News/>}  />
                    </Routes>
                </main>
                <footer>
                <img src='public/Resources/erb.png' alt='Erb' title='Sokol Hnojice'/>
                        <div className='footerNav'>
                            <ul>
                                <li><NavLink to='Novinky'>Novinky</NavLink></li>
                                <li><NavLink to='Historie'>Historie</NavLink></li>
                                <li><NavLink to='Galerie'>Galerie</NavLink></li>
                                <li><NavLink to='Dokumenty'>Dokumenty</NavLink></li>
                                <li><NavLink to='Kontaky'>Kontaky</NavLink></li>
                            </ul>
                    </div>
                </footer>
                </div>
            </Fragment>
    );
}
 
export default Home;